@keyframes fadeout {
    from { opacity: 1; }
    to { opacity: 0; }
}

.fade-out {
    animation-name: fadeout;
    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-delay: 5s;
}