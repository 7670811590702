.form-checkbox {
    margin: auto;
    display: block;
}


.fc .fc-title {
    display: block;
    white-space: normal;
    font-size: 0.85em;
}

.fc .fc-timeGridWeek-view .fc-title {
    display: inline-block;
    white-space: normal;
    font-size: 0.85em;
}

/* 
.fc .fc-axis{
    font-size: auto;
}
  */

.fc-slats td {
    font-size: 0.7em;
    height: 7px;
    width: auto;
}

.fc-toolbar {
    padding: 1em;
}

.fc-toolbar h2 {
    font-size: 1.3em;
}

.fc-button-primary {
    color: #fff;
    background-color: #5e72e4;
    border-color: #5e72e4;
}

.fc-button-primary:hover {
    color: #fff;
    background-color: #5163c7;
    border-color: #5163c7;
}

.fc-button-primary:disabled {
    color: #fff;
    background-color: #2c3e50;
    border-color: #2c3e50;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
    color: #fff;
    background-color: #5163c7;
    border-color: #5163c7;
}

.fc-toolbar.fc-header-toolbar{
    margin-bottom: 0px;
    font-size: 11px;
}

.fc-event .fc-time{
    font-size: smaller;
    float: none;
    color: whitesmoke;
}

a.fc-more{
    color: crimson;
}

.fc-content{
    cursor: pointer;
}


li.nav-item{
    cursor:pointer;
}

.fc-view, .fc-view > table{
    overflow: visible;
}


.form-control-lg, .input-group-lg .form-control, .input-group-text{
    font-size: 0.8rem !important;
}

.fc button .fc-icon {
    top: -0.05em; 
}



.fc-dayGrid-view .fc-body .fc-row{
    min-height: 2.5em;
}

